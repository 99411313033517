
import React from 'react';
import { Link } from 'react-router-dom';
// import logo from './logo.svg';
import logo from '../assets/Logo.png';
// import { FaCalendar, FaHospitalSymbol, FaTable,FaPersonBooth, FaAirFreshener , FaSearch} from 'react-icons/fa'
// import {Pointer} from 'react-web-vector-icons';


function header(){


    return(
        <div style={{background:'#ab1414',width:"100%",height:50,display:'flex',flexDirection:'row',margin:0,padding:0,position:'fixed', zIndex:200}}>
        {/* <img src={logo} width={70} height={55} style={{width:40,height:40,backgroundColor:'white',borderRadius:'50%',margin:5}} /> */}
        <Link to={'/'}  style={{textDecoration:'none'}}>
        <p style={{alignSelf:'center',textAlign:'center',color:'white',fontSize:20,margin:10}}>JOps CMS</p>
        </Link>
        <img src={"https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"}  width={40} height={40} style={{justifySelf:'right',borderRadius:'50%', alignSelf:'right',right:10,position:'absolute',margin:5}} />
        </div>
    );
}

export default header;