
import { Region } from "./region";
import { Woreda } from "./woreda";
import { Zone } from "./zone";
export  class Institution {
    institutionId: string;
    name: string ;
    woreda: Woreda;
    region: Region;
    zone: Zone

    constructor(
        institutionId: string = '',
        name: string = '',
        woreda: Woreda = new Woreda(),
        region: Region = new Region (),
        zone: Zone= new Zone()
    ){
            this.institutionId = institutionId;
            this.name = name;
            this.woreda = woreda;
            this.region = region;
            this.zone = zone;
    }
}