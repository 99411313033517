import React, { useEffect,useState } from 'react';
import {MdKeyboardArrowDown} from 'react-icons/md';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {BiBookContent} from 'react-icons/bi';
import {AiFillSchedule,AiFillDelete} from 'react-icons/ai';
  import {FiEdit2} from 'react-icons/fi';
  import {GrFormNextLink} from 'react-icons/gr';
  import {GrFormPreviousLink} from 'react-icons/gr';

function DisplayEvents(){

    const [eventDetail, setEventDetail] = useState<any>([]);
    const [searchQuery,setSeachQuery] = useState<string>("");

    const [content,setContent] = useState<Boolean>(false);
    const [contentList,setContentList] = useState<String[]>(["name","city","region","woreda","Date",]);
    const [selectedContent,setSelectedContent] = useState<String>("");

    const [Filter,setFilter] = useState<Boolean>(false);
    const [FilterList,setFilterList] = useState<String[]>(["contains","Not Contains","Starts With","Ends With",]);
    const [selectedFilter,setSelectedFilter] = useState<String>("");
    const [limit, setLimit] = useState<Number>(10);
    const [skip,setSkip] = useState<number>(0);


    function assignSkip(number: number) {
      setSkip(number+10);
    }

    let navigate = useNavigate(); 
     const routeChange = () =>{ 
    let path = `/citydetail`; 
    navigate(path);
    }

     useEffect(()=>{
        axios.get(`http://localhost:4000/cms/getevents/all/${skip}/${limit}`)
        .then(function (response) {
        console.log(response);
          // handle success
        //   return response.data[0];
       setEventDetail(response.data.data);


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

    },[skip,limit]);

    const getSearchString = (event: any) =>{

        setSeachQuery(event?.target?.value);
    }


    const searchString = (searchValue: string) =>{


      if(selectedContent != "" && selectedFilter != ""){
        axios.get(`http://localhost:4000/cms/getevents/${selectedFilter}/${selectedContent}/${searchValue}`)
        .then(function (response) {
          // console.log(response);
          // handle success
        //   return response.data[0];
       setEventDetail(response.data.data[0]);


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
      }
      else{
        axios.get(`http://localhost:4000/cms/getevents/contains/name/${searchValue}`)
        .then(function (response) {
          // console.log(response);

          // handle success
        //   return response.data[0];
       setEventDetail(response.data.data);


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
      }
        
    }
    const details = (id: string) =>{
      axios.get(`http://localhost/cms/getcitybyid/${id}`)
      .then(function (response) {
        console.log(response);
        // handle success
      //   return response.data[0];
     console.log(response.data.data);


      })
      .catch(function (error) {
        // handle error
        console.log(error);
        return error;
  
      })
      .then(function () {
        // always executed
      
        
  
      });
  }
    

    return(
     
        <div style={{width:'92%',height:'80%',display:'flex',flexDirection:'column',background:'white', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'6.5%'}}>
      
            <div style={{width:'100%',backgroundColor:'#e4e7e9',height:80,alignContent:'center',alignItems:'center', display:'flex',flexDirection:'row',margin:0,padding:0}}>
            <h1 style={{marginRight:100, marginLeft:10, color:'#a09c9c'}}>Events</h1>
            
            <input onChange={e => getSearchString(e)} style={{border:"0px", borderBottom:"1px gray solid",background:'transparent', height:40,width:400, outline:'none',padding:0,margin:10,alignSelf:'center'}}></input>
            <button onClick={()=>{
            searchString(searchQuery);

            }} style={{height:30,margin:10,width:60,alignSelf:'center'}}> Search </button>
           {/* <div style={{width:100,height:300,marginTop:10}}> */}
           <div style={{width:100,height:content? contentList.length *30 :30,marginTop:content?'10%':0, backgroundColor:'lightgray',marginRight:5,display:'flex',overflow:'visible'}}>
            <p style={{fontSize:14,margin:7,marginTop:7}}> {selectedContent == ""? "Contents": selectedContent}</p>
            <MdKeyboardArrowDown onClick={()=>{ setContent(true) }} style={{marginTop:7}} />
            {content && 
              <div style={{width:100,position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:contentList.length *30,background:'white'}}> 
              {contentList.map(data =>{
                  return(
                    <div   onClick={()=>{ setContent(false)
                       setSelectedContent(data)}}  style={{background:'lightgray',margin:1,padding:5}}>{data}</div>
                  );
              })}
            </div>
            }
          
           </div>
   
            {/* </div> */}
           

            <div style={{width:100,height:Filter? FilterList.length * 30 :30,marginTop:Filter?'10%':0, backgroundColor:'lightgray',marginRight:5,display:'flex',overflow:'auto'}}>
            <p style={{fontSize:14,margin:7,marginTop:7}}> {selectedFilter == ""? "Filter": selectedFilter}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilter(true) }} style={{marginTop:7}} />
            {Filter && 
              <div style={{width:100,position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:FilterList.length *30,background:'white'}}> 
              {FilterList.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilter(false)
                       setSelectedFilter(data)}}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data}</div>
                  );
              })}
            </div>
            }
          
           </div>

          

            
            </div>
            <div style={{width:'100%',alignSelf:'center',margin:0,padding:0, height:40,verticalAlign:'center',alignItems:'center', background:'#e4e7e9',position:'relative',display:'flex',flexDirection:'row',overflow:'hidden'}}> 
            
            <p style={{fontSize:15,minWidth:50,maxWidth:50, textAlign:'center', fontWeight:'bold'}}>ID</p>
            <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:5, textAlign:'center', fontWeight:'bold'}}>Name</p>
            <p style={{fontSize:15,minWidth:200,maxWidth:200,margin:5, textAlign:'center', fontWeight:'bold',color:'black'}}>Request Date</p>
            <p style={{fontSize:15,minWidth:200,maxWidth:200,margin:5, textAlign:'center', fontWeight:'bold'}}>Start Date</p>
            <p style={{fontSize:15,minWidth:200,maxWidth:200,margin:5, textAlign:'center', fontWeight:'bold',color:'black'}}>End Date</p>
            <p style={{fontSize:15,minWidth:180,maxWidth:180,margin:5, textAlign:'center', fontWeight:'bold'}}>Status</p>


</div>
            <div style={{width:'100%',height:window.innerHeight-(window.innerHeight*10/100),background:'lightgray',position:'relative',margin:5,display:'flex',flexDirection:'column',overflow:'scroll', scrollbarWidth:"none",alignItems:'center'}}> 
               {
                   eventDetail.map((data:any,index:number)=>{
return(

    <div  style={{outline:'none', textDecoration:"none",color:"black", border:"none", width:'99.5%',minHeight:50,background:index % 2 == 0 ? '#e4e7e9' : 'white',display:'flex',flexDirection:'row',alignItems:'center', overflow:'hidden',marginTop:1}}>
    <p style={{fontSize:15,minWidth:50,maxWidth:50,textAlign:'center', verticalAlign:'center'}}>{data?.id}</p>
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:5,textAlign:'center', verticalAlign:'center'}}>{data?.name}</p>
    <p style={{fontSize:15,minWidth:200,maxWidth:200,margin:5, textAlign:'center', verticalAlign:'center'}}>{data?.requestDate.slice(0,10)}</p>
    <p style={{fontSize:15,minWidth:200,maxWidth:200,margin:5, textAlign:'center', verticalAlign:'center'}}> {data?.startDate.slice(0,10)}</p>
    <p style={{fontSize:15,minWidth:200,maxWidth:200,margin:5, textAlign:'center', verticalAlign:'center'}}>{data?.endDate.slice(0,10)}</p>
    <p style={{fontSize:15,minWidth:180,maxWidth:180,margin:5, textAlign:'center', verticalAlign:'center'}}> {data?.status}</p>
    <Link className="containtButton" to={`/eventDetail/${data?.eventId}`} style={{background:'black',padding:7,borderRadius:10,margin:3, color:'white', boxShadow:'2px 6px 8px gray',display:'flex',position:'absolute',right:50}}> <FiEdit2 size={15} color={'white'}></FiEdit2></Link>
    <Link className="containtButton" to={`/eventDetail/${data?.cityId}`} style={{background:'black',padding:7,borderRadius:10,margin:3,marginRight:9, color:'white', boxShadow:'2px 6px 8px gray',position:'absolute',right:10}}> <AiFillDelete size={15} color={'white'}></AiFillDelete></Link>
    
    </div>
                   )
                   })
 
               }
              
                
           </div>
           <div style={{width:'100%',flexDirection:'row',display:'flex', alignItems:'flex-end', height:50, background:'white',position:"fixed", bottom:0,borderTop:'1px #ab1414 solid' }}>
           <button onClick={()=>{assignSkip((skip-20))}} style={{ backgroundColor:'white',outline:'none',border:'none',marginLeft:'80%',color:'gray'}}><GrFormPreviousLink size={40} color="gray"></GrFormPreviousLink> </button>
             <p style={{color:"gray",margin:0,padding:0,marginBottom:10}}>{skip} - {skip+10}</p>
               <button onClick={()=>{assignSkip((skip))}} style={{ backgroundColor:'white',outline:'none',border:'none',margin:0,color:'gray'}}><GrFormNextLink size={40} style={{color:'gray'}} color="gray"></GrFormNextLink> </button>
           </div>

        </div>
    );
}






export default DisplayEvents;