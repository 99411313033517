import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { City } from '../../model/city';
import { CityLevel } from '../../model/cityLevel';
import { Region } from '../../model/region';

function DetailCity() {
    
    const [cityDetail, setCityDetail] = useState<City> (new City());
    // const [cityDetail,setCityDetail] = useState<City>(new City());
    const [cityLevel,setCityLevel] = useState<CityLevel[]>([]);
    const [selectedCityLevel,setSelectedCityLevel] = useState<CityLevel>();
    const [selectedRegion,setSelectedRegion] = useState<Region>(new Region());
const [region, setRegion] = useState<Region[]>([]);

    const [selectCitlyLevel,setSelectCitlyLevel] = useState<boolean>(false);
    const [selectRegion, setSelectRegion] = useState<boolean>(false);


    const {cityId } = useParams();

    useEffect(()=>{
        axios.get(`http://localhost:4000/cms/getcitybyid/${cityId}`)
        .then(function (response) {

       setSelectedCityLevel({"name": response.data.data[0].cityLevel , 
        "cityLevelId": response.data.data[0].cityLevelId})
        setSelectedRegion({"name": response.data.data[0].region ,
    "regionId": response.data.data[0].region})

       setCityDetail({
        "cityId": response.data.data[0].cityId,
        "name": response.data.data[0].name,
        "cityLevel": selectedCityLevel as CityLevel,
        "region": selectedRegion
       });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });


        axios.get(`http://localhost:4000/cms/getCityLevel/all`)
        .then(function (response) {
            setCityLevel(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getregion/all`)
        .then(function (response) {
            setRegion(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });



    },[]);

    const updateCity = (cityDetal: City, cityId: any) =>{
        axios.post(`http://localhost:4000/cms/updatecity/${cityId}`,{
            data: cityDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       

        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

        return(
            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'lightgray', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <p style={{fontSize:'x-large',margin:15}}>City-Detail : {cityDetail?.name} </p>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input onChange={(e) => {
                 var city: City = cityDetail as City;
                    city.name = e.target.value;
                    setCityDetail(city)

            }} defaultValue={cityDetail?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            
            <div onClick={()=>{ setSelectCitlyLevel(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>City Level</p>
           

            <input onChange={(e) => {
                 var city: City = cityDetail as City;
                    city.cityLevel.name = e.target.value;
                    setCityDetail(city)

            }} defaultValue={selectedCityLevel?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            { selectCitlyLevel && 
               <div style={{height:cityLevel? cityLevel.length * 30 :30,zIndex:700}}>
               {cityLevel.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectCitlyLevel(false)
                     
                        
                       setSelectedCityLevel({
                        "name": data.name,
                       "cityLevelId": data.cityLevelId})
                        
                       var city: City = cityDetail as City;
                            city.cityLevel = {
                                "name": data.name,
                               "cityLevelId": data.cityLevelId};

                       setCityDetail(city)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }

            <div onClick={()=>{setSelectRegion(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Region</p>
            <input onChange={() => {
                 var city: City = cityDetail as City;
                 city.region = cityDetail?.region;
                    setCityDetail(city)
                }}
             defaultValue={selectedRegion?.name}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            { selectRegion && 
               <div style={{height:400,zIndex:700}}>
               {region.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectRegion(false)
                     
                        
                       setSelectedRegion({
                        "name": data.name,
                       "regionId": data.regionId})
                        
                       var city: City = cityDetail as City;
                            city.region = {
                                "name": data.name,
                               "regionId": data.regionId};

                       setCityDetail(city)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }
            
            
            
               
               </div>
               <button onClick={() => { updateCity(cityDetail as City, cityId) 
            }} style={{width:150,height:50,background:'#ab1414',position:'absolute',right:10, bottom:0, color:'white'}}>Update </button>

           
              
                </div>

            

        );
    }
    
    export default DetailCity;
