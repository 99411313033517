export class Region{
    regionId: string;
    name: string;

    constructor(
        regionId: string= '',
        name: string= ''
    ){
        this.regionId= regionId;
        this.name= name; 
    }
    
}