import React, { useEffect,useState,createContext } from 'react';
import logo from './logo.svg';
import { FaCalendar, FaHospitalSymbol, FaTable,FaPersonBooth, FaAirFreshener , FaSearch} from 'react-icons/fa'
import {MdKeyboardArrowDown} from 'react-icons/md';
import {findEventsByName} from '../api/endpoints';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";

function Home(){


 

  function Iframe(props: any) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }

  

    return(
<div style={{width:'92%',height:'100vh',backgroundColor:'lightgray', alignContent:'center',marginLeft:'6.5%',marginTop:50}}>
{/* <img src={"https://img.freepik.com/free-vector/colleagues-preparing-corporate-party-time-management-deadline-brand-event_335657-3083.jpg?w=2000"} width={"80%"} height={"50%"} style={{marginLeft:'15%'}}/> */}
<Iframe iframe={`<iframe src="https://calendar.google.com/calendar/embed?src=c_bhhgqjl78inqs322rclv4lcdpc%40group.calendar.google.com&ctz=Africa%2FNairobi" style="border: 0" width="100%" height="700" frameborder="0" scrolling="no"></iframe>`} />
</div>

    );


}

export default Home;