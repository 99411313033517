import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Event } from '../../model/event';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Funder } from '../../model/funder';
import { Project } from '../../model/project';
import { Product } from '../../model/products';

function DetailEvent() {
    
    const [eventDetail, setEventDetail] = useState<Event> (new Event());
    const [startDate, setStartDate] = useState(new Date());    
    const [endDate, setEndDate] = useState(new Date());
    const [requestDate, setRequestDate] = useState(new Date());

    const [session,setSession] = useState<Boolean>(false);
    const [sessionList,setSessionList] = useState<string[]>(["Full Day","Half Day"]);
    const [selectedSession,setSelectedSession] = useState<string>("");

    const [funders,setFunders] = useState<Funder[]>([]);
    const [funder,setFunder] = useState<Boolean>(false);
    const [selectedFunder,setSelectedFunder] = useState<Funder>();

    const [projects,setProjects] = useState<Project[]>([]);
    const [project,setProject] = useState<Boolean>(false);
    const [selectedProject,setSelectedProject] = useState<Project>();

    const [products,setProducts] = useState<Product[]>([]);
    const [product,setProduct] = useState<Boolean>(false);
    const [selectedProduct,setSelectedProduct] = useState<Product>();

    const [status,setStatus] = useState<Boolean>(false);
    const [statusList,setStatusList] = useState<string[]>(["Open","Closed", "Upcoming"]);
    const [selectedStatus,setSelectedStatus] = useState<string>("");

    const {eventId } = useParams();

    useEffect(()=>{
        axios.get(`http://localhost:4000/cms/geteventbyid/${eventId}`)
        .then(function (response) {
    //    setEventDetail(response.data.data[0]);
    setSelectedFunder({
        "name": response.data.data[0][0].funderName,
        "funderId": response.data.data[0][0].funderName,
        "shortName": response.data.data[0][0].funderName
    })

    setSelectedProject({
        "name": response.data.data[0][0].projectname,
        "projectId": response.data.data[0][0].projectname,
        "code": response.data.data[0][0].projectname
    })

    setSelectedStatus(response.data.data[0][0].status);
    setSelectedSession(response.data.data[0][0].session);
    setStartDate(new Date(response.data.data[0][0].startDate));
    setEndDate(new Date(response.data.data[0][0].endDate));
    setRequestDate(new Date(response.data.data[0][0].requestDate));

    console.log("funder Id ",);
    setEventDetail({
        eventId: response.data.data[0][0].eventId,
        name: response.data.data[0][0].name,
        venueId: response.data.data[0][0].venueId,
        description: response.data.data[0][0].description,
        funderId: ({
            "name": response.data.data[0][0].fundername,
            "funderId": response.data.data[0][0].fundername,
            "shortName": response.data.data[0][0].fundername
        }),
        projectId: ({
            "name": response.data.data[0][0].projectname,
            "projectId": response.data.data[0][0].projectname,
            "code": response.data.data[0][0].projectname
        }),
        productId: ({
            "name": response.data.data[0][0].productname,
            "productId": response.data.data[0][0].productname
        }),
        budgetAccountId: response.data.data[0][0].budgetAccountId,
        eventTypeId: response.data.data[0][0].eventTypeId,
        productServiceId: response.data.data[0][0].productServiceId,
        requestDate: requestDate,
        startDate: startDate,
        endDate: endDate,
        session: selectedSession,
        perdiemProvided: response.data.data[0][0].perdiemProvided,
        useGovernmentPerdiemRate: response.data.data[0][0].useGovernmentPerdiemRate,
        breakfastProvided: response.data.data[0][0].breakfastProvided,
        lunchProvided: response.data.data[0][0].lunchProvided,
        dinnerProvided:response.data.data[0][0].dinnerProvided,
        hotelProvided:response.data.data[0][0].hotelProvided,
        transportProvided: response.data.data[0][0].transportProvided,
        status: response.data.data[0][0].status,
    })
    console.log('Where is this', response.data.data[0][0]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getfunder/all`)
        .then(function (response) {
            setFunders(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getproject/all`)
        .then(function (response) {
            setProjects(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getproject/all`)
        .then(function (response) {
            setProjects(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getproduct/all`)
        .then(function (response) {
            setProducts(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

    },[]);

        return(
            
            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'lightgray', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'6.5%'}}>
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <h1 style={{marginRight:100, marginLeft:10, marginBottom: 10,color:'#a09c9c'}}>Detail-Event: {eventDetail?.name}</h1>

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input defaultValue={eventDetail?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>


            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Description</p>
            <input defaultValue={eventDetail?.description} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Session</p>
            <input  onClick={()=>{setSession(true)}} defaultValue={selectedSession} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />
           
            </div>
            {session&&
             <div style={{height:sessionList? sessionList.length * 30 :30,zIndex:700}}>
             {sessionList.map((data,index) =>{
                return(
                  <div   onClick={()=>{ setSession(false)
                   
                      
                     setSelectedSession(data)
                      
                     var event: Event = eventDetail as Event;
                          event.session = data;

                     setEventDetail(event)
                  
                    }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data}</div>
                );
            })}
             </div>

            }
            {/* <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Start Date</p>
            <DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
            </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>End Date</p>
                <DatePicker selected={endDate} onChange={(date:Date) => setEndDate(date)} />
            </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Requested Date</p>
                <DatePicker selected={requestDate} onChange={(date:Date) => setRequestDate(date)} />
            </div> */}
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Status</p>
            <input onClick={()=>{setStatus(true)}} defaultValue={selectedStatus} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            {status&&
             <div style={{height:statusList? statusList.length * 30 :30,zIndex:700}}>
             {statusList.map((data,index) =>{
                return(
                  <div   onClick={()=>{ setStatus(false)
                   
                      
                     setSelectedStatus(data)
                      
                   
                  
                    }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data}</div>
                );
            })}
            
            </div>
            }
            
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Funder</p>
            <input onClick={()=>{setFunder(true)}} defaultValue={eventDetail.funderId?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            
            {funder&&
             <div style={{height:funders? funders.length * 30 :30,zIndex:700}}>
             {funders.map((data,index) =>{
                return(
                  <div   onClick={()=>{ setFunder(false)
                   
                      
                     setSelectedFunder(data)
                     var event: Event = eventDetail as Event;
                     event.funderId = data;
                  

                setEventDetail(event)
                   
                  
                    }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                );
            })}
            
            </div>
            }            
            
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Project</p>
            <input onClick={()=>{setProject(true)}} defaultValue={eventDetail?.projectId?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            {project&&
             <div style={{height:projects? projects.length * 30 :30,zIndex:700}}>
             {projects.map((data,index) =>{
                return(
                  <div   onClick={()=>{ setProject(false)
                   
                      
                     setSelectedProject(data)
                     var event: Event = eventDetail as Event;
                     event.projectId = data;

                setEventDetail(event)
                   
                  
                    }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                );
            })}
            
            </div>
            }  

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Project</p>
            <input onClick={()=>{setProduct(true)}} defaultValue={eventDetail.productId?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            {product&&
             <div style={{height:products? products.length * 10 :30,zIndex:700,overflowY:'scroll', flex:'none'}}>
             {products.map((data,index) =>{
                return(
                  <div   onClick={()=>{ setProduct(false)
                   
                      
                     setSelectedProduct(data)
                     var event: Event = eventDetail as Event;
                     event.productId = data;

                setEventDetail(event)
                   
                  
                    }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                );
            })}
            
            </div>
            }  
               
               </div>
               <button style={{width:150,height:50,background:'#ab1414',position:'absolute',right:10, bottom:0, color:'white'}}>Update </button>

           
              
                </div>

            

        );
    }
    
    export default DetailEvent;
