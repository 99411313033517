export  class Funder {
    funderId: string;
    name: string ;
    shortName: String;

    constructor(
        funderId: string = "",
        name: string = '',
        shortName: string = ''
    ){
            this.funderId = funderId;
            this.name = name;
            this.shortName = shortName;
    }
}