import React, { useEffect,useState } from 'react';
import logo from './logo.svg';
import { FaCalendar, FaHospitalSymbol, FaTable,FaPersonBooth, FaAirFreshener , FaSearch} from 'react-icons/fa'
import {MdKeyboardArrowDown} from 'react-icons/md';
// import {findEventsByName} from '../api/endpoints';
import {FiEdit2} from 'react-icons/fi';
import {GrFormNextLink} from 'react-icons/gr';
import {GrFormPreviousLink} from 'react-icons/gr';

import {AiFillSchedule,AiFillDelete} from 'react-icons/ai';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";

function DisplayJsiUsers(){

  function assignSkip(number: number) {
    setSkip(number+10);
  }
    const [jsiUserDetail, setJsiUserDetail] = useState<any>([]);
    const [searchQuery,setSeachQuery] = useState<string>("");
    const [limit, setLimit] = useState<Number>(10);
    const [skip,setSkip] = useState<number>(0);
    const [content,setContent] = useState<Boolean>(false);
    const [selectedContent,setSelectedContent] = useState<String>("");
    const [contentList,setContentList] = useState<String[]>(["firstName","lastName","email","postion","phoneNumber"]);
    const [Filter,setFilter] = useState<Boolean>(false);
    const [FilterList,setFilterList] = useState<String[]>(["contains","Not Contains","Starts With","Ends With"]);
    const [selectedFilter,setSelectedFilter] = useState<String>("");
    

     useEffect(()=>{
        axios.get(`http://localhost:4000/cms/getjsiuser/all/${skip}/${limit}`)
        .then(function (response) {
        console.log(response);
          // handle success
        //   return response.data[0];
       setJsiUserDetail(response.data.data);


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

    },[]);

    const getSearchString = (event: any) =>{

        setSeachQuery(event?.target?.value);
    }

    const searchString = (searchValue: string) =>{
      if(selectedContent != "" && selectedFilter != ""){
        axios.get(`http://localhost:4000/cms/getjsiusers/${selectedFilter}/${selectedContent}/${searchValue}`)
        .then(function (response) {
          console.log(response);
          // handle success
        //   return response.data[0];
        console.log(response.data.data[0]);
       setJsiUserDetail(response.data.data[0]);


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
      }
      else{
        axios.get(`http://localhost:4000/cms/getjsiuser/${searchValue}`)
        .then(function (response) {
          console.log(response);
          // handle success
        //   return response.data[0];
       setJsiUserDetail(response.data.data);


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
      }
        
    }

    

    return(

        <div style={{width:'97%',height:'80%',position:'relative',display:'flex',flexDirection:'column',background:'white', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'6.5%'}}>
            <div style={{width:'100%',backgroundColor:'#e4e7e9',height:80,alignSelf:'center',alignContent:'center',alignItems:'center', display:'flex',flexDirection:'row',margin:0,padding:0}}>
            <h1 style={{marginRight:100, marginLeft:10, color:'#a09c9c'}}>Jsi User</h1>
            <input onChange={e => getSearchString(e)} style={{border:"0px", borderBottom:"1px gray solid",background:'transparent', height:40,width:400, outline:'none',padding:0,margin:10,alignSelf:'center'}}></input>
            <button onClick={()=>{
            searchString(searchQuery);

            }} style={{height:30,margin:10,width:60,alignSelf:'center'}}> Search </button>
           
           <div style={{width:100,height:content? contentList.length *30 :30,marginTop:content?'10%':0, backgroundColor:'lightgray',marginRight:5,display:'flex',overflow:'visible'}}>
            <p style={{fontSize:14,margin:7,marginTop:7}}> {selectedContent == ""? "Columns": selectedContent}</p>
            <MdKeyboardArrowDown onClick={()=>{ setContent(true) }} style={{marginTop:7}} />
            {content && 
              <div style={{width:100,position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:contentList.length *30,background:'white'}}> 
              {contentList.map(data =>{
                  return(
                    <div   onClick={()=>{ setContent(false)
                       setSelectedContent(data)}}  style={{background:'lightgray',margin:1,padding:5}}>{data}</div>
                  );
              })}
            </div>
            }
          
           </div>
   
            {/* </div> */}
           

            <div style={{width:100,height:Filter? FilterList.length * 30 :30,marginTop:Filter?'10%':0, backgroundColor:'lightgray',marginRight:5,display:'flex',overflow:'auto'}}>
            <p style={{fontSize:14,margin:7,marginTop:7}}> {selectedFilter == ""? "Filter": selectedFilter}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilter(true) }} style={{marginTop:7}} />
            {Filter && 
              <div style={{width:100,position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:FilterList.length *30,background:'white'}}> 
              {FilterList.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilter(false)
                       setSelectedFilter(data)}}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data}</div>
                  );
              })}
            </div>
            }
          
           </div>

           

          <button style={{width:91,height:30,backgroundColor:'#00c700', outline:'none',border:'none', marginRight:5, float:'right'}}>
          <a href='/createJsiUser' style={{height:30,margin:10,width:60,alignSelf:'center', color: 'white', textDecoration: 'none'}}> Create New </a>
          </button>

            
            </div>
           
            <div style={{width:'97%',height:27,background:'lightgray',position:'relative',margin:5,display:'flex',flexDirection:'column',overflow:'hidden'}}> 
            <button style={{outline:'none',border:"none", width:'100%',minHeight:50,background:'white',display:'flex',flexDirection:'row',overflow:'hidden',}}>
            <p style={{fontSize:15,minWidth:75,maxWidth:310,margin:5, textAlign:'center', fontWeight:'bold'}}>ID</p> 
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:5, textAlign:'center', fontWeight:'bold'}}>First Name</p>
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:5, textAlign:'center', fontWeight:'bold'}}>Position</p>
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:5, textAlign:'center', fontWeight:'bold'}}> Phone Number</p>
    </button>
</div>
            <div style={{width:'97%',height:window.innerHeight-(window.innerHeight*20/100),background:'lightgray',position:'relative',margin:10,display:'flex',flexDirection:'column',overflow:'scroll', scrollbarWidth:"none"}}> 
               {
                   jsiUserDetail.map((data:any,index:number)=>{
return(
    <button style={{outline:'none',border:"none", width:'99%',minHeight:50,background:index % 2 == 0 ? '#e4e7e9' : 'white',display:'flex',flexDirection:'row',overflow:'hidden',boxShadow:'2px 2px 2px 0px gray',margin:3}}>
    <p style={{fontSize:15,minWidth:70,maxWidth:10,margin:3,textAlign:'center', verticalAlign:'center'}}>{data?.id}</p>
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:3,textAlign:'center', verticalAlign:'center'}}>{data?.firstName}</p>
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:3,textAlign:'center', verticalAlign:'center'}}>{data?.position}</p>
    <p style={{fontSize:15,minWidth:310,maxWidth:310,margin:3,textAlign:'center', verticalAlign:'center'}}> {data?.phoneNumber}</p>
    <Link className="containtButton" to={`/jsiuserDetail/${data?.squidexId}`} style={{background:'black',padding:7,borderRadius:10,margin:3, color:'white', boxShadow:'2px 6px 8px gray'}}> <FiEdit2 size={15} color={'white'}></FiEdit2></Link>
    <Link className="containtButton" to={`/jsiuserDetail/${data?.squidexId}`} style={{background:'black',padding:7,borderRadius:10,margin:3,marginRight:9, color:'white', boxShadow:'2px 6px 8px gray'}}> <AiFillDelete size={15} color={'white'}></AiFillDelete></Link>
    </button>
                   )
                   })
 
               }
              
                
           </div>

           <div style={{width:'100%',flexDirection:'row',display:'flex', alignItems:'flex-end', height:50, background:'white',position:"fixed", bottom:0,borderTop:'1px #ab1414 solid' }}>
           <button onClick={()=>{assignSkip((skip-20))}} style={{ backgroundColor:'white',outline:'none',border:'none',marginLeft:'80%',color:'gray'}}><GrFormPreviousLink size={40} color="gray"></GrFormPreviousLink> </button>
             <p style={{color:"gray",margin:0,padding:0,marginBottom:10}}>{skip} - {skip+10}</p>
               <button onClick={()=>{assignSkip((skip))}} style={{ backgroundColor:'white',outline:'none',border:'none',margin:0,color:'gray'}}><GrFormNextLink size={40} style={{color:'gray'}} color="gray"></GrFormNextLink> </button>
           </div>
        </div>
    );
}

export default DisplayJsiUsers;