import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavigationBar from './component/navigationBar';
import Header from './component/header';
import DetailJsiUser from './pages/jsiUser/displayDetail';
import DisplayUsers from './pages/user/displayUsers';
import DisplayJsiUsers from './pages/jsiUser/displayJsiUsers';
import DisplayCities from './pages/city/displayCities';
import CreateCities from './pages/city/createCity';
import CreateInstitution from './pages/institution/createInstitution';
import DisplayInstitutions from './pages/institution/displayInstitutions';
import DisplayWoredas from './pages/woreda/displayWoredas';
import DisplayEvents from './pages/event/displayEvents';
import DisplayDetailCity from './pages/city/displayDetailCity';
import DetailUser from './pages/user/displayDetail';
import DetailWoreda from './pages/woreda/displayDetail';
import Createworeda from './pages/woreda/createWoreda';
import CreateUser from './pages/user/createUser';
import DisplayDetailInstitution from './pages/institution/displayDetail';
import DisplayDetailEvent from './pages/event/displayDetailEvent';
import Login from './pages/login';
import Home from './pages/home';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import CreateJsiUser from './pages/jsiUser/createJsiUser';


function App() {
  if(false){
    return(
      <Route exact path="/login" element={<Login/>}  />

    );
  }else{
    return (
      <Router>
  <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',background:'lightgray',flexWrap:'wrap'}}>
  
  
    <Header/>


  
    <div style={{display:'flex',flexDirection:'row'}}>
    <NavigationBar/>
    
    
    </div>
  
  <Routes>
    
    <Route exact path="/" element={<Home/>} />
  
        
  <Route exact path="/login" element={<Login/>}  />
  <Route exact path="/jsiUserDetail/:squidexId" element={<DetailJsiUser/>}/>
  <Route exact path="/users" element={<DisplayUsers/>}  />
  <Route exact path="/jsiusers" element={<DisplayJsiUsers/>}  />
  <Route exact path="/institutions" element={<DisplayInstitutions/>}  />
  <Route exact path="/woredas" element={<DisplayWoredas/>}  />
  <Route exact path="/cities" element={<DisplayCities/>}/>
  <Route exact path="/createCities" element={<CreateCities/>}/>
  <Route exact path="/createInstitution" element={<CreateInstitution/>}/>
  <Route exact path="/createUser" element={<CreateUser/>}/>
  <Route exact path="/events" element={<DisplayEvents/>}/>
  <Route exact path="/cityDetail/:cityId" element={<DisplayDetailCity/>}/>
  <Route exact path="/institutionDetail/:institutionId" element={<DisplayDetailInstitution/>}/>
  <Route exact path="/eventDetail/:eventId" element={<DisplayDetailEvent/>}/>
  <Route exact path="/woredaDetail/:woredaId" element={<DetailWoreda/>}/>
  <Route exact path="/userDetail/:userId" element={<DetailUser/>}/>
  <Route exact path="/createWoreda" element={<Createworeda/>}/>
  <Route exact path="/createJsiUser" element={<CreateJsiUser/>}/>

  
    
  </Routes>
  
  
  </div>  
      </Router>
    );

  }

}

export default App;
