import { Funder } from "./funder";
import { Product } from "./products";
import { Project } from "./project";

export class Event {
    eventId: string;
    name: string;
    venueId: string;
    description: string;
    funderId: Funder;
    projectId: Project;
    productId: Product;
    budgetAccountId: string;
    eventTypeId: string;
    productServiceId: string;
    requestDate: Date;
    startDate: Date;
    endDate: Date;
    session: string;
    perdiemProvided: Boolean;
    useGovernmentPerdiemRate: Boolean;
    breakfastProvided: Boolean;
    lunchProvided: Boolean;
    dinnerProvided:Boolean;
    hotelProvided:Boolean;
    transportProvided: Boolean;
    status: string;

    constructor(
        eventId: string = '',
        name: string = '',
        venueId: string = '',
        description: string = '',
        funderId: Funder = new Funder(),
        projectId: Project = new Project(),
        productId: Product = new Product(),
        budgetAccountId: string = '',
        eventTypeId: string = '',
        productServiceId: string = '',
        requestDate: Date = new Date(),
        startDate: Date =new Date(),
        endDate: Date = new Date(),
        session: string = '',
        perdiemProvided: Boolean = false,
        useGovernmentPerdiemRate: Boolean = false,
        breakfastProvided: Boolean = false,
        lunchProvided: Boolean = false,
        dinnerProvided:Boolean = false,
        hotelProvided:Boolean = false,
        transportProvided: Boolean = false,
        status: string = "",
    ){
        this.eventId = eventId;
        this.name = name;
        this.venueId = venueId;
        this.description = description;
        this.funderId = funderId;
        this.projectId = projectId;
        this.productId = productId;
        this. budgetAccountId = budgetAccountId;
        this.eventTypeId = eventTypeId;
        this.productServiceId = productServiceId;
        this.requestDate = requestDate;
        this.startDate = startDate;
        this. endDate = endDate;
        this. session = session;
        this. perdiemProvided = perdiemProvided;
        this.useGovernmentPerdiemRate = useGovernmentPerdiemRate;
        this.breakfastProvided = breakfastProvided;
        this.lunchProvided = lunchProvided;
        this. dinnerProvided = dinnerProvided;
        this. hotelProvided = hotelProvided;
        this. transportProvided = transportProvided;
        this.status= status
    }
}