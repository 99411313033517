import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { City } from '../../model/city';
import { Woreda } from '../../model/woreda';
import { Zone } from '../../model/zone';
import { Region } from '../../model/region';
import {MdKeyboardArrowDown} from 'react-icons/md';
import { Console } from 'console';
import { Institution } from '../../model/institution';
import { JsiUser } from '../../model/jsiUser'
function CreateJsiUser() {
    
    const [eventDetail, setEventDetail] = useState<any[]> ([]);
    const [jsiUserDetail,setJsiUserDetail] = useState<JsiUser>(new JsiUser());

    

    const createJsiUser = (jsiUserDetal: JsiUser) =>{
        axios.post(`http://localhost:4000/cms/createjsiUser`,{
            data: jsiUserDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       
            alert("created successfully");

        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }
   

        return(
            

            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'lightgray', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <div>
                <p style={{fontSize:'xx-large',margin:'15px'}}> Create JsiUser</p>
                 </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}> First Name</p>
            <input 
            onChange={(e) => {
                 var jsiUser: JsiUser = jsiUserDetail as JsiUser;
                    jsiUser.firstName = e.target.value;
                    setJsiUserDetail(jsiUser)

            }} 
            placeholder={"First Name"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}> Last Name</p>
            <input 
            onChange={(e) => {
                 var jsiUser: JsiUser = jsiUserDetail as JsiUser;
                    jsiUser.lastName = e.target.value;
                    setJsiUserDetail(jsiUser)

            }} 
            placeholder={"Last Name"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}> Email</p>
            <input 
            onChange={(e) => {
                 var jsiUser: JsiUser = jsiUserDetail as JsiUser;
                    jsiUser.email = e.target.value;
                    setJsiUserDetail(jsiUser)

            }} 
            placeholder={"Email"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}> Position </p>
            <input 
            onChange={(e) => {
                 var jsiUser: JsiUser = jsiUserDetail as JsiUser;
                    jsiUser.position = e.target.value;
                    setJsiUserDetail(jsiUser)

            }} 
            placeholder={"Postion"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}> Phone Number</p>
            <input 
            onChange={(e) => {
                 var jsiUser: JsiUser = jsiUserDetail as JsiUser;
                    jsiUser.phoneNumber= e.target.value;
                    setJsiUserDetail(jsiUser)

            }} 
            placeholder={"Phone Number"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            
           
            
            
            
               
               </div>
               <button onClick={() => { 
               
                
                var jsiUser: JsiUser = jsiUserDetail as JsiUser;
              
                
          
                createJsiUser(jsiUserDetail as JsiUser);
                }} style={{width:150,height:50,background:'#197a05',position:'absolute',right:10, bottom:0, color:'white'}}>Create </button>

           
              
                </div>

            

        );
    }
    
    export default CreateJsiUser;
