import { City } from "./city";
import { Institution } from "./institution";
import { Region } from "./region";

export  class User{
    firstName: string;
    lastName: string;
    isJsiEmployee: boolean;
    institution: Institution;
    city: City;
    bank: string;
    bankAccountNo: string;
    phonNumber: string;
    region: Region;

    constructor(
    firstName: string = '',
    lastName: string = '',
    isJsiEmployee: boolean = false,
    institution: Institution = new Institution(),
    city: City = new City(),
    bank: string = '',
    bankAccountNo: string = '',
    phonNumber: string = '',
    region: Region = new Region(),
    ){
            this.firstName = firstName;
            this.lastName = lastName;
            this.isJsiEmployee = isJsiEmployee;
            this.institution = institution;
            this.city = city;
            this.bank = bank;
            this.bankAccountNo = bankAccountNo;
            this.phonNumber = phonNumber;
            this.region = region;
    }
}