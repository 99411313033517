export class CityLevel{
    cityLevelId: string;
    name: string;

    constructor(
        cityLevelId: string= '',
        name: string= ''
    ){
        this.cityLevelId= cityLevelId;
        this.name= name; 
    }
    
}