import { Region } from "./region";
import { Zone } from "./zone";
export class Woreda{
    name: string ;
    region: Region;
    zone: Zone;

    constructor(
        name: string = '',
        region: Region = new Region() ,
        zone: Zone = new Zone()
    ){
            this.name = name;
            this.region = region;
            this.zone = zone;
    }
}