import {styles} from './styles/styles'; 
import React, { useState } from 'react';
import logo from './logo.svg';
// import { FaCalendar, FaHospitalSymbol, FaTable,FaPersonBooth, FaAirFreshener , FaSearch} from 'react-icons/fa'
import {BiBookContent} from 'react-icons/bi';
import {AiFillSchedule} from 'react-icons/ai';
// import {Pointer} from 'react-web-vector-icons';
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";


function NavigationBar() {
  

  const [displayContent,setContent] = useState<boolean>(false);
  const [widthLength,setWidth] = useState<string>('1%');

  const clickContent = () =>{
    if(displayContent){
      setContent(false)
      setWidth('1%')
      // window.location.href = "/";
    }else{
      setContent(true);
      setWidth('27%')
    }
  }

  
  return (
    <div style={{width:widthLength,backgroundColor:'white',margin:0,padding:0,display:'flex',flexDirection:'row',position:'fixed',zIndex:200,marginTop:51}}>
    <div style={styles.ljfrs}>
      
        <button onClick={()=>{ clickContent()}} style={{ display:'flex',flexDirection:'column',alignContent:'center',padding:25,alignItems:'center',border:'none',backgroundColor:displayContent?"rgb(192,192,192)" :""}} >
           <BiBookContent size={50} color={"gray"} style={styles.vovjw}/>
<p style={styles.budnd}>Content</p>
        </button>

        <button style={styles.cevyj}>
           <AiFillSchedule size={50} color={"gray"} style={styles.vovjw}/>
<p style={styles.budnd}>Schema</p>
        </button>

        <button style={styles.cevyj}>
           <BiBookContent size={50} color={"gray"} style={styles.vovjw}/>
<p style={styles.budnd}>Assets</p>
        </button>

        
         

    </div>
    {displayContent &&
    <div style={{width:'100%',height:'100%',backgroundColor:'rgb(192,192,192)',display:'flex',flexDirection:'column'}}>
    <p style={{textAlign:'center',color:'gray',fontSize:20,margin:10}}> Content</p>
    <input placeholder='Search Content Type' style={{width:'80%',marginBottom:10, height:28,alignSelf:'center',marginTop:10,backgroundColor:'lightgray',border:'none', outline:'none',padding:5}}>
    </input>

    <Link onClick={()=>{clickContent()}}  to="/users" className='containtButton' style={{width:'100%',textAlign:'center', verticalAlign:'center', color:'white', textDecoration:'none',alignItems:'center',flexDirection:'row', borderBottom:'1px lightgray solid', height:48,alignSelf:'center',marginTop:22,border:'none', outline:'none',padding:0,justifyContent:'center',alignContent:'center'}}> 
<p style={{verticalAlign:'center',textAlign:'center',marginTop:"5%"}}> User Profiles </p>

</Link>

<Link onClick={()=>{clickContent()}} className='containtButton'  to="/institutions" style={{width:'100%',textAlign:'center', color:'white', textDecoration:'none',alignItems:'center',flexDirection:'row', borderBottom:'1px lightgray solid', height:48,alignSelf:'center',marginTop:2,border:'none', outline:'none',padding:0}}> 
<p style={{verticalAlign:'center',textAlign:'center',marginTop:"5%"}}> Institutions</p>
</Link>

<Link onClick={()=>{clickContent()}} className='containtButton'  to="/woredas" style={{width:'100%',textAlign:'center', color:'white', textDecoration:'none',alignItems:'center',flexDirection:'row', borderBottom:'1px lightgray solid', height:48,alignSelf:'center',marginTop:2,border:'none', outline:'none',padding:0}}> 
<p style={{verticalAlign:'center',textAlign:'center',marginTop:"5%"}}> Woredas</p>
</Link>


<Link onClick={()=>{clickContent()}}   className='containtButton' to="/cities" style={{width:'100%',textAlign:'center', color:'white', textDecoration:'none',alignItems:'center',flexDirection:'row', borderBottom:'1px lightgray solid', height:48,alignSelf:'center',marginTop:2,border:'none', outline:'none',padding:0}}> 
<p style={{verticalAlign:'center',textAlign:'center',marginTop:"5%"}}> Cities</p>
</Link>

<Link onClick={()=>{clickContent()}}   to="/jsiusers"className='containtButton' style={{width:'100%',textAlign:'center', color:'white', textDecoration:'none',alignItems:'center',flexDirection:'row', borderBottom:'1px lightgray solid', height:48,alignSelf:'center',marginTop:2,border:'none', outline:'none',padding:0}}> 
<p style={{verticalAlign:'center',textAlign:'center',marginTop:"5%"}}> Jsi Users </p>
</Link>

<Link onClick={()=>{clickContent()}} className='containtButton' to="/events" style={{width:'100%',textAlign:'center', verticalAlign:'center', color:'white', textDecoration:'none',alignItems:'center',flexDirection:'row', borderBottom:'1px lightgray solid', height:48,alignSelf:'center',marginTop:2,border:'none', outline:'none',padding:0}}> 
<p style={{verticalAlign:'center',textAlign:'center',marginTop:"5%"}}>  Events </p>
</Link>


    </div>

}
    </div>
  );
}

export default NavigationBar;

