import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { City } from '../../model/city';
import { Region } from '../../model/region';
import { CityLevel } from '../../model/cityLevel';
import {MdKeyboardArrowDown} from 'react-icons/md';

function DetailCity() {
    
    const [eventDetail, setEventDetail] = useState<any[]> ([]);
    const [cityDetail,setCityDetail] = useState<City>(new City());

    const [Filter,setFilter] = useState<Boolean>(false);
    const [selectedFilter,setSelectedFilter] = useState<String>("");


    const [city, setCity] = useState<City>(new City());
    const [regions,setRegions] = useState<Region[]>([]);
    const [cityLevels,setCityLevels] = useState<CityLevel[]>([]);
    const [selectedRegion,setSelectedRegion] = useState<Region>();
    const [selectedCityLevel,setSelectedCityLevel] = useState<CityLevel>();





    const [Filters,setFilters] = useState<Boolean>(false);
    const [FilterLists,setFilterLists] = useState<string[]>(["Contains","Equals","NotEquals","Starts","Ends",]);
    const [selectedFilters,setSelectedFilters] = useState<String>("");


    useEffect(()=>{
      
      axios.get(`http://localhost:4000/cms/getregion/all`)
    .then(function (response) {
   
   setRegions(response?.data?.data);


    }).catch((err) => { 

      console.log("ERRR: ", err);
    })

    axios.get(`http://localhost:4000/cms/getcitylevel/all`)
    .then(function (response) {
   
      setCityLevels(response?.data?.data);
   
   
       }).catch((err) => { 
   
         console.log("ERRR: ", err);
       })
  
    },[])


    

    const createCity = (cityDetal: City) =>{
        axios.post(`http://localhost:4000/cms/createcity`,{
            data: cityDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       
          alert("Created Successfully")


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

        return(
            

            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'white', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <div>
                <p style={{fontSize:'xx-large',margin:'15px'}}> Create City</p>
                 </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input 
            onChange={(e) => {
                 var city: City = cityDetail as City;
                    city.name = e.target.value;
                    setCityDetail(city)

            }} 
            placeholder={"City Name"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>City Level</p>
            <div style={{width:'98%', height:Filters? FilterLists.length * 30 :30,marginTop:Filters?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setFilters(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedCityLevel?.name == ""? "City Level": selectedCityLevel?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilter(true) }} style={{marginTop:7}} />
            {Filters && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:FilterLists.length *30,background:'white'}}> 
              {cityLevels.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilters(false)
                     
                        
                       setSelectedCityLevel({
                        "name": data.name,
                       "cityLevelId": data.cityLevelId})
                       
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Region</p>
            <div style={{width:'98%', height:Filter? regions.length * 30 :30,marginTop:Filter?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setFilter(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedRegion?.name == "" ? `Region`: selectedRegion?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilter(true) }} style={{marginTop:7}} />
            {Filter && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:regions.length *30,background:'white'}}> 
              {regions.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilter(false)
                        
                        setSelectedRegion({
                          "name": data.name,
                          "regionId": data.regionId,
                        })
                        
                        
                        
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            
            
            
            
               
               </div>
               <button onClick={() => { 
                   var city: City = cityDetail as City;
                city.cityLevel= selectedCityLevel as CityLevel;
                city.region = selectedRegion as Region;
                setCityDetail(city);
                console.log(city)
                createCity(cityDetail as City) }} style={{width:150,height:50,background:'#197a05',position:'absolute',right:10, bottom:0, color:'white'}}>Create </button>

           
              
                </div>

            

        );
    }
    
    export default DetailCity;
