import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { City } from '../../model/city';
import { Institution } from '../../model/institution';
import { Region } from '../../model/region';
import { Zone } from '../../model/zone';
import { Woreda } from '../../model/woreda';

function DetailInstitution() {
    
    const [institutionDetail, setInstitutionDetail] = useState<Institution> ( new Institution());
    const [cityDetail,setCityDetail] = useState<City>(new City());

    const [selectedRegion,setSelectedRegion] = useState<Region>(new Region());
    const [region, setRegion] = useState<Region[]>([]);
    const [selectRegion, setSelectRegion] = useState<boolean>(false);

    const [selectedWoreda, setSelectedWoreda] = useState<Woreda>(new Woreda());
    const [woreda, setWoreda] = useState<Woreda[]>([]);
    const [selectWoreda, setSelectWoreda] = useState<Boolean>(false);

    const [selectedZone, setSelectedZone] = useState<Zone>(new Zone());
    const [zone, setZone] = useState<Zone[]>([]);
    const [selectZone, setSelectZone] = useState<Boolean>(false);

    const { institutionId } = useParams();

    useEffect(()=>{
        axios.get(`http://localhost:4000/cms/getinstitutionbyid/${institutionId}`)
        .then(function (response) {
            

        setSelectedRegion({"name": response.data.data[0].region ,
    "regionId": response.data.data[0].region})

        setSelectedZone({"name":response.data.data[0].zone,
                        "region": selectedRegion,
                        "zoneId": ""})

        setSelectedWoreda({"name": response.data.data[0].woreda,
        "region": selectedRegion,
        "zone": selectedZone})

       setInstitutionDetail({
        "institutionId": "",
        "name": response.data.data[0].name,        
        "woreda": selectedWoreda,
        "region": selectedRegion,
        "zone": selectedZone
        });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getregion/all`)
        .then(function (response) {
            setRegion(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getworeda/all`)
        .then(function (response) {
            setWoreda(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getzone/all`)
        .then(function (response) {
            setZone(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    },[]);

    const updateInstitution = (institutionDetal: Institution) =>{
        axios.post(`http://localhost:4000/cms/updateinstitution/${institutionId}`,{
            data: institutionDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

        return(
            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'lightgray', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <p style={{fontSize:'x-large',margin:15}}>Institution-Detail : {institutionDetail.name} </p>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input onChange={(e) => {
                 var institution: Institution= institutionDetail as Institution;
                    institution.name = e.target.value;
                    setInstitutionDetail(institution)

            }} defaultValue={institutionDetail.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>


            <div onClick={()=>{ setSelectWoreda(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Woreda</p>
            <input onChange={(e) => {
                  var institution: Institution= institutionDetail as Institution;
                  institution.woreda.name = e.target.value;
                  setInstitutionDetail(institution)

            }} defaultValue={institutionDetail.woreda?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            { selectWoreda && 
               <div style={{width:'98%', height:woreda? woreda.length * 30 :30, maxHeight: '70vh',marginTop:woreda?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,overflowY:'scroll'}}>
               {woreda.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectWoreda(false)
                     
                        
                       setSelectedWoreda({
                        "name": data.name,
                        "region": data.region,
                        "zone": data.zone})
                        
                        var institution: Institution= institutionDetail as Institution;
                       institution.woreda = {
                                "name": data.name,
                                "region": data.region,
                                "zone": data.zone};

                       setInstitutionDetail(institution)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }
            <div onClick={()=>{ setSelectZone(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Zone</p>
            <input onChange={(e) => {
                 var institution: Institution= institutionDetail as Institution;
                 institution.zone.name = e.target.value;
                 setInstitutionDetail(institution)
            }} defaultValue={institutionDetail.zone?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            
            { selectZone && 
               <div style={{width:'98%', height:zone? zone.length * 30 :30, maxHeight: '70vh',marginTop:zone?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,overflowY:'scroll'}}>
               {zone.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectZone(false)
                     
                        
                       setSelectedZone({
                        "name": data.name,
                        "region": data.region,
                        "zoneId":data.zoneId})
                        
                        var institution: Institution= institutionDetail as Institution;
                       institution.zone = {
                        "name": data.name,
                        "region": data.region,
                        "zoneId":data.zoneId};

                       setInstitutionDetail(institution)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }

            <div onClick={()=>{setSelectRegion(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Region</p>
            <input onChange={(e) => {
                 var city: City = cityDetail as City;
                    city.region.name = e.target.value;
                    setCityDetail(city)
                }} defaultValue={institutionDetail.region?.name}
               style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            { selectRegion && 
               <div style={{width:'98%', height:region? region.length * 30 :30, maxHeight: '70vh',marginTop:region?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,overflowY:'scroll'}}>
               {region.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectRegion(false)
                     
                        
                       setSelectedRegion({
                        "name": data.name,
                        "regionId": data.regionId})
                        
                        var institution: Institution= institutionDetail as Institution;
                       institution.region = {
                        "name": data.name,
                        "regionId": data.regionId};

                       setInstitutionDetail(institution)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }
            
            
               
               </div>
               <button onClick={() => { updateInstitution(institutionDetail) }} style={{width:150,height:50,background:'#ab1414',position:'absolute',right:10, bottom:0, color:'white'}}>Update </button>

           
              
                </div>

            

        );
    }
    
    export default DetailInstitution;
