import React from 'react';

export const styles: any = { 
 txiwn:{ 
 clear:'both', 
width:100, 
height:window.innerHeight-52, 
backgroundColor:'white', 
alignSelf:'flex-start', 
display:'flex', 
flexDirection:'column'},
ljfrs:{ 
 clear:'both',width:100,height:1009,backgroundColor:'white',alignSelf:'flex-start',display:'flex',flexDirection:'column',borderRight:'1px #ab1414 solid'}, 
cevyj:{ 
 display:'flex',flexDirection:'column',alignContent:'center',padding:25,alignItems:'center',border:'none',}, 
vovjw:{ 
 alignSelf:'center',margin:0,padding:0}, 
budnd:{ 
 textAlign:'center',margin:0,padding:0,color:'gray'}, 
 
ugayx:{ 
 display:'flex', 
flexDirection:'column', 
alignContent:'center', 
padding:0, 
alignItems:'center', 
border:'none'}, 
 
xnfye:{ 
 alignSelf:'center', 
margin:0, 
padding:0}, 
 
tbece:{ 
 textAlign:'center', 
margin:0, 
padding:0, 
color:'gray'}, 
 
 
}