import { Region } from "./region";
import { CityLevel } from "./cityLevel";
export  class City {
    cityId: string;
    name: string ;
    cityLevel: CityLevel;
    region: Region;

    constructor(
        cityId: string = "",
        name: string = '',
        cityLevel: CityLevel = new CityLevel(),
        region: Region = new Region() 
    ){
            this.cityId = cityId;
            this.name = name;
            this.cityLevel = cityLevel;
            this.region = region;
    }
}