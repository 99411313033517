export  class Project {
    projectId: string;
    name: string ;
    code: String;

    constructor(
        projectId: string = "",
        name: string = '',
        code: string = ''
    ){
            this.projectId = projectId;
            this.name = name;
            this.code = code;
    }
}