import { Region } from "./region";
export class Zone{
    name: string ;
    region: Region;
    zoneId: String;

    constructor(
        name: string = '',
        region: Region = new Region() ,
        zoneId: String = ''
    ){
            this.name = name;
            this.region = region;
            this.zoneId = zoneId;
    }
}