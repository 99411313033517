import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { User } from '../../model/user';
import { City } from '../../model/city';
import { Institution } from '../../model/institution';
import { Region } from '../../model/region';
import {MdKeyboardArrowDown} from 'react-icons/md';

function CreateUser() {
    
    const [eventDetail, setEventDetail] = useState<any[]> ([]);
    const [userDetail,setUserDetail] = useState<User>(new User());

    const [Filter,setFilter] = useState<Boolean>(false);
    const [regions,setRegions] = useState<Region[]>([]);
    const [selectedRegion,setSelectedRegion] = useState<Region>();

    const [Filters,setFilters] = useState<Boolean>(false);
    const [Institution, setInstitution] = useState<Institution[]>([]);
    const [selectedInstituion, setSelectedInstitution] = useState<Institution>();

    const [city, setCity] = useState<Boolean>(false);
    const [Citys, setCitys] = useState<City[]>([]);
    const [selectedCity, setSelectedCity] = useState<City>();
    

    const createUser = (userDetal: User) =>{
        axios.post(`http://localhost:4000/cms/createuserprofiles`,{
            data: userDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       
          alert("Created Successfully")

        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

    useEffect(()=>{
      
      axios.get(`http://localhost:4000/cms/getregion/all`)
    .then(function (response) {
   
   setRegions(response?.data?.data);


    }).catch((err) => { 

      console.log("ERRR: ", err);
    })

    axios.get(`http://localhost:4000/cms/getcitylookup/all`)
    .then(function (response) {
   
   setCitys(response.data.data);


    }).catch((err) => { 

      console.log("ERRR: ", err);
    })

    axios.get(`http://localhost:4000/cms/getinstitutionslookup/all`)
    .then(function (response) {
   
   setInstitution(response?.data?.data);


    }).catch((err) => { 

      console.log("ERRR: ", err);
    })

    
  
    },[])

        return(
          <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'lightgray', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'6.5%'}}>
          <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
          <p style={{fontSize:'x-large',margin:15}}>Create User Profile{eventDetail[0]?.firstName} </p>
          <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
          <p style={{fontSize:14,margin:5}}>First Name</p>
          <input onChange={(e) => {
          var user: User = userDetail as User;
              user.firstName = e.target.value;
              setUserDetail(user)

          }}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

          </div>

          <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
          <p style={{fontSize:14,margin:5}}>Middle Name</p>
          <input onChange={(e) => {
          var user: User = userDetail as User;
              user.lastName = e.target.value;
              setUserDetail(user)
          }}
          style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

          </div>

          <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
          <p style={{fontSize:14,margin:5}}>Phone Number</p>
          <input onChange={(e) => {
          var user: User = userDetail as User;
              user.phonNumber = e.target.value;
              setUserDetail(user)

          }}style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

          </div>

          <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Region</p>
            <div style={{width:'98%', height:Filter? regions.length * 30 :30,marginTop:Filter?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setFilter(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedRegion?.name == "" ? `Region`: selectedRegion?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilter(true) }} style={{marginTop:7}} />
            {Filter && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:200, overflowY:'scroll',background:'white'}}> 
              {regions.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilter(false)
                        
                        setSelectedRegion({
                          "name": data.name,
                          "regionId": data.regionId,
                        })
                        
                        
                        
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>

          <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
          <p style={{fontSize:14,margin:5}}>Is Jsi Employee</p>
          <input onChange={(e) => {
          var user: User = userDetail as User;
              user.isJsiEmployee = e.target.value == "true"? true : false;
              setUserDetail(user)
          }}
            style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

          </div>

          <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>City</p>
            <div style={{width:'98%', marginTop:city?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setCity(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedCity?.name == "" ? `City`: selectedCity?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setCity(true) }} style={{marginTop:7}} />
            {city && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:200, overflowY:'scroll',background:'white'}}> 
              {Citys.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setCity(false)
                        
                        setSelectedCity({
                          "cityId": data.cityId,
                          "name": data.name,
                          "region": data.region,
                          "cityLevel": data.cityLevel
                        })
                        
                        
                        
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>

          <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Institution</p>
            <div style={{width:'98%', marginTop:Filters?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setFilters(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedInstituion?.name == "" ? `Institution`: selectedInstituion?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilters(true) }} style={{marginTop:7}} />
            {Filters && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:200, overflowY:'scroll',background:'white'}}> 
              {Institution.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilters(false)
                        
                        setSelectedInstitution({
                          "institutionId": data.institutionId,
                          "name": data.name,
                          "region": data.region,
                          "woreda": data.woreda,
                          "zone": data.zone

                        })
                        
                        
                        
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>

          <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
          <p style={{fontSize:14,margin:5}}>Bank</p>
          <input onChange={(e) => {
          var user: User = userDetail as User;
              user.bank = e.target.value;
              setUserDetail(user)
          }}
            style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

          </div>

          <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
          <p style={{fontSize:14,margin:5}}>Bank Account Number</p>
          <input onChange={(e) => {
          var user: User = userDetail as User;
              user.bankAccountNo = e.target.value;
              setUserDetail(user)
          }}
            style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

          </div>




          </div>
          <button onClick={() => { 
            var user: User = userDetail as User;
            user.region = selectedRegion as Region;
            user.institution = selectedInstituion as Institution
            setUserDetail(user)
            createUser(userDetail as User) }} style={{width:150,height:50,background:'#197a05',position:'absolute',right:10, bottom:0, color:'white'}}>Create </button>



          </div>

        );
    }
    
    export default CreateUser;
