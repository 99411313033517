import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { City } from '../../model/city';
import { Woreda } from '../../model/woreda';
import { Zone } from '../../model/zone';
import { Region } from '../../model/region';
import {MdKeyboardArrowDown} from 'react-icons/md';
import { Console } from 'console';
import { Institution } from '../../model/institution';

function DetailInstitution() {
    
    const [eventDetail, setEventDetail] = useState<any[]> ([]);
    const [institutionDetail,setinstitutionDetail] = useState<Institution>(new Institution());

    const [Woreda,setWoreda] = useState<Boolean>(false);
    const [WoredaList,setWoredaList] = useState<Woreda[]>([]);
    const [selectedWoreda,setSelectedWoreda] = useState<Woreda>();

    const [Zone,setZone] = useState<Boolean>(false);
    const [ZoneLists,setZoneLists] = useState<Zone[]>([]);
    const [selectedZone,setSelectedZone] = useState<Zone>();

    const [Region,setRegion] = useState<Boolean>(false);
    const [RegionLists,setRegionLists] = useState<Region[]>([]);
    const [selectedRegion,setSelectedRegion] = useState<Region>();
    

    const createInstitution = (institutionDetal: Institution) =>{
        axios.post(`http://localhost:4000/cms/createinstitution`,{
            data: institutionDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }
    useEffect(()=>{
      
      axios.get(`http://localhost:4000/cms/getregion/all`)
    .then(function (response) {
   
   setRegionLists(response?.data?.data);


    }).catch((err) => { 

      console.log("ERRR: ", err);
    })

    axios.get(`http://localhost:4000/cms/getzone/all`)
    .then(function (response) {
   
      setZoneLists(response?.data?.data);
   
   
       }).catch((err) => { 
   
         console.log("ERRR: ", err);
       })

       axios.get(`http://localhost:4000/cms/getworeda/all`)
       .then(function (response){
        setWoredaList(response?.data?.data);
       }).catch((err) =>{
        console.log(err);
       })
  
    },[])

        return(
            

            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'white', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <div>
                <p style={{fontSize:'xx-large',margin:'15px'}}> Create Institution</p>
                 </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input 
            onChange={(e) => {
                 var institution: Institution = institutionDetail as Institution;
                    institution.name = e.target.value;
                    setinstitutionDetail(institution)

            }} 
            placeholder={"City Name"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Woreda</p>
            <div style={{width:'98%', height:Woreda? WoredaList.length * 30 :30, maxHeight: '70vh',marginTop:Woreda?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflowY:'scroll'}}>
            
            <p onClick={()=>{ setWoreda(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedWoreda?.name == ""? "Filter": selectedWoreda?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setWoreda(true) }} style={{marginTop:7}} />
            {Woreda && 
              <div style={{width:'93%',position:'absolute',zIndex:3, display:'flex',flexDirection:'column', height:WoredaList.length *30,background:'white'}}> 
              {WoredaList.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setWoreda(false)
                       setSelectedWoreda(
                        {"name": data.name,
                          "region": data.region,
                            "zone": data.zone})}}
                         style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Zone</p>
            <div style={{width:'98%', height:Zone? ZoneLists.length * 30 :30,marginTop:Zone?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setZone(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedZone?.name == ""? "Filter": selectedZone?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setZone(true) }} style={{marginTop:7}} />
            {Zone && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:ZoneLists.length *30,background:'white'}}> 
              {ZoneLists.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setZone(false)
                        var institution: Institution = institutionDetail as Institution;
                        
                       setSelectedZone({
                        "name": data.name,
                        "region": data.region,
                        "zoneId": data.zoneId
                       })}}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Region</p>
            <div style={{width:'98%', height:Region? RegionLists.length * 30 :30,marginTop:Region?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setRegion(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedRegion?.name == ""? "Filter": selectedRegion?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setRegion(true) }} style={{marginTop:7}} />
            {Region && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:RegionLists.length *30,background:'white'}}> 
              {RegionLists.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setRegion(false)
                        var institution: Institution = institutionDetail as Institution;
                       
                       setSelectedRegion({
                        "name": data.name,
                        "regionId": data.regionId
                       })}}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            
            
            
               
               </div>
               <button onClick={() => { 
               
                
                var institution: Institution = institutionDetail as Institution;
                institution.region = selectedRegion as Region;
                institution.woreda = selectedWoreda as Woreda;
                institution.zone = selectedZone as Zone;
                setinstitutionDetail(institution);
                createInstitution(institutionDetail as Institution);
                }} style={{width:150,height:50,background:'#197a05',position:'absolute',right:10, bottom:0, color:'white'}}>Create </button>

           
              
                </div>

            

        );
    }
    
    export default DetailInstitution;
