import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Region } from '../../model/region';
import { Institution } from '../../model/institution';
import { Woreda } from '../../model/woreda';
import { Zone } from '../../model/zone';
import { User } from '../../model/user';
import { City } from '../../model/city';
import { CityLevel } from '../../model/cityLevel';

function DetailUser() {
    
    const [eventDetail, setEventDetail] = useState<any[]> ([]);
    const [userDetail,setUserDetail] = useState<User>(new User());

    const [selectedRegion,setSelectedRegion] = useState<Region>(new Region());
    const [region, setRegion] = useState<Region[]>([]);
    const [selectRegion, setSelectRegion] = useState<boolean>(false);

    const [selectedEmployee,setSelectedEmployee] = useState<String>("");
    const [employee, setEmployee] = useState<string[]>(["True", "False"]);
    const [selectEmployee, setSelectEmployee] = useState<boolean>(false);

    const [selectedInstitution,setSelectedInstitution] = useState<Institution>(new Institution());
    const [institution, setInstitution] = useState<Institution[]>([]);
    const [selectInstitution, setSelectInstitution] = useState<boolean>(false);
    const [selectCity, setSelectCity] = useState<boolean>(false);

    const [selectedWoreda, setSelectedWoreda] = useState<Woreda>(new Woreda());
    const [selectedZone, setSelectedZone] = useState<Zone>(new Zone());
    const [selectedCity, setSelectedCity] = useState<City>(new City());
    const [city, setCity] = useState<City[]>([]);
    
    const [selectedCityLevel, setSelectedCityLevel] = useState<CityLevel>(new CityLevel());

    const {userId } = useParams();

    useEffect(()=>{
        axios.get(`http://localhost:4000/cms/getuserbyid/${userId}`)
        .then(function (response) {
            setSelectedRegion({"name": response.data.data[0].region ,
            "regionId": response.data.data[0].region})

            setSelectedZone({"name":response.data.data[0].institution,
            "region": selectedRegion,
            "zoneId": ""})

        setSelectedWoreda({"name": response.data.data[0].institution,
        "region": selectedRegion,
        "zone": selectedZone})

        setSelectedCity({"name": response.data.data[0].city,
        "region": selectedRegion,
        "cityLevel": selectedCityLevel,
        "cityId": ""})

        setSelectedCityLevel({"name": response.data.data[0].institution,
        "cityLevelId": response.data.data[0].institution})

        setSelectedInstitution({ 
            "name": response.data.data[0].institution,        
            "woreda": selectedWoreda,
            "region": selectedRegion,
            "zone": selectedZone,
            "institutionId": ""
            });
           
       setUserDetail({
        "firstName": response.data.data[0].firstName,
        "lastName": response.data.data[0].middleName,
        "isJsiEmployee": response.data.data[0].isJsiEmployee,
        "institution": {"name": response.data.data[0].institution,        
                        "woreda": selectedWoreda,
                        "region": selectedRegion,
                        "zone": selectedZone,
                        "institutionId": ""},
        "city": {"name": response.data.data[0].city,
                "region": selectedRegion,
                "cityLevel": selectedCityLevel,
                "cityId": ""} ,
        "bank": response.data.data[0].bank,
        "bankAccountNo": response.data.data[0].bankAccountNo,
        "phonNumber": response.data.data[0].phoneNumber,
        "region": {"name": response.data.data[0].region ,
                    "regionId": response.data.data[0].region},
       });

        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getregion/all`)
        .then(function (response) {
            setRegion(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getinstitutionslookup/all`)
        .then(function (response) {
            setInstitution(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getcitylookup/all`)
        .then(function (response) {
            setCity(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    },[]);

    const updateUser = (userDetal: User) =>{
        console.log("full detail: ",userDetail);
        axios.post(`http://localhost:4000/cms/updateuserprofiles/${userId}`,{
            data: userDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       
            alert("updated successfully");

        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

        return(
            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'lightgray', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'6.5%'}}>
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <p style={{fontSize:'x-large',margin:15}}>user-Detail : {userDetail.firstName} </p>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>First Name</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.firstName = e.target.value;
                    setUserDetail(user)

            }} defaultValue={userDetail.firstName} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Middle Name</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.lastName = e.target.value;
                    setUserDetail(user)
                }}
             defaultValue={userDetail.lastName}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Phone Number</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.phonNumber = e.target.value;
                    setUserDetail(user)

            }} defaultValue={userDetail.phonNumber} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div onClick={()=>{setSelectRegion(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Region</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.region.name = e.target.value;
                    setUserDetail(user)
                }}
             defaultValue={userDetail?.region?.name}  
             style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}}/>

            </div>
            { selectRegion && 
               <div style={{width:'98%',  height:region? region.length * 30 :30, maxHeight: '70vh',marginTop:region?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10, overflowY:'scroll', flex:'none'}}>
               {region.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectRegion(false)
                     
                        
                       setSelectedRegion({
                        "name": data.name,
                       "regionId": data.regionId})
                        
                       var user: User = userDetail as User;
                            user.region = {
                                "name": data.name,
                               "regionId": data.regionId};

                       setUserDetail(user)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }

            <div onClick={()=>{setSelectCity(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>City</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.city.name = e.target.value;
                    setUserDetail(user)
                }}
             defaultValue={userDetail.city?.name}  
             style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}}/>

            </div>
            { selectCity && 
               <div style={{width:'98%', height:city? city.length * 30 :30, maxHeight: '25vh',marginTop:city?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,overflowY:'scroll', flex:'none'}}>
               {city.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectCity(false)
                     
                        
                       setSelectedCity({
                        "name": data.name,
                        "cityLevel": data.cityLevel,
                        "region": data.region,
                        "cityId": data.cityId
                    })
                        
                       var user: User = userDetail as User;
                            user.city = {
                                "name": data.name,
                        "cityLevel": data.cityLevel,
                        "region": data.region,
                        "cityId": data.cityId};

                       setUserDetail(user)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }

            <div onClick={()=>{setSelectEmployee(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Is Jsi Employee</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.isJsiEmployee = e.target.value as unknown as boolean;
                    setUserDetail(user)
                }} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            { selectEmployee && 
               <div style={{width:'98%',  height:employee? employee.length * 30 :30, maxHeight: '70vh',marginTop:employee?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10, overflowY:'scroll', flex:'none'}}>
               {employee.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectEmployee(false)
                     
                        
                       setSelectedEmployee(data)
                        
                       var user: User = userDetail as User;
                            user.isJsiEmployee = {data} as unknown as boolean;

                       setUserDetail(user)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data}</div>
                  );
              })}
               </div>

            }

            <div onClick={()=>{setSelectInstitution(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Institution</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.institution.name = e.target.value;
                    setUserDetail(user)
                }}
             defaultValue={userDetail.institution.name}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            { selectInstitution && 
               <div style={{width:'98%', height:institution? institution.length * 30 :30, maxHeight: '25vh',marginTop:institution?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,overflowY:'scroll', flex:'none'}}>
               {institution.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectInstitution(false)
                     
                        setSelectedInstitution({
                            "name": data.name,
                            "institutionId": data.institutionId,
                            "region": data.region,
                            "woreda": data.woreda,
                            "zone": data.zone
                        })
                        
                       var user: User = userDetail as User;
                            user.institution = {
                                "name": data.name,
                                "institutionId": data.institutionId,
                                "region": data.region,
                                "woreda": data.woreda,
                                "zone": data.zone};

                       setUserDetail(user)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Bank</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.bank = e.target.value;
                    setUserDetail(user)
                }}
             defaultValue={userDetail.bank}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Bank Account Number</p>
            <input onChange={(e) => {
                 var user: User = userDetail as User;
                    user.bankAccountNo = e.target.value;
                    setUserDetail(user)
                }}
             defaultValue={userDetail.bankAccountNo}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            
            
            
               
               </div>
               <button onClick={() => { updateUser(userDetail as User) }} style={{width:150,height:50,background:'#ab1414',position:'absolute',right:10, bottom:0, color:'white'}}>Update </button>

           
              
                </div>

            

        );
    }
    
    export default DetailUser;
