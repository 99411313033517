import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Woreda } from '../../model/woreda';
import { Region } from '../../model/region';
import { Zone } from '../../model/zone';
import { City } from '../../model/city';

function DetailWoreda() {
    
    const [woredaDetail,setWoredaDetail] = useState<Woreda>(new Woreda());

    const [selectedRegion,setSelectedRegion] = useState<Region>(new Region());
    const [region, setRegion] = useState<Region[]>([]);
    const [selectRegion, setSelectRegion] = useState<boolean>(false);


    const [selectedZone, setSelectedZone] = useState<Zone>(new Zone());
    const [zone, setZone] = useState<Zone[]>([]);
    const [selectZone, setSelectZone] = useState<Boolean>(false);

    const { woredaId } = useParams();

    useEffect(()=>{
        axios.get(`http://localhost:4000/cms/getworedabyid/${woredaId}`)
        .then(function (response) {
       setWoredaDetail(response.data.data[0]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        axios.get(`http://localhost:4000/cms/getregion/all`)
        .then(function (response) {
            setRegion(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });

        

        axios.get(`http://localhost:4000/cms/getzone/all`)
        .then(function (response) {
            setZone(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    },[]);

    const updateWoreda = (woredaDetal: Woreda) =>{
        axios.post(`http://localhost:4000/cms/updateworeda/${woredaId}`,{
            data: woredaDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

        return(
            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'white', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <p style={{fontSize:'x-large',margin:15}}>Woreda-Detail : {woredaDetail.name} </p>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input onChange={(e) => {
                 var woreda: Woreda = woredaDetail as Woreda;
                    woreda.name = e.target.value;
                    setWoredaDetail(woreda)

            }} defaultValue={woredaDetail.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>



            <div onClick={()=>{ setSelectZone(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Zone</p>
            <input onChange={(e) => {
                 var woreda: Woreda = woredaDetail as Woreda;
                    woreda.zone.name = e.target.value;
                    setWoredaDetail(woreda)

            }} defaultValue={woredaDetail.zone?.name} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>
            { selectZone && 
               <div style={{width:'98%', height:zone? zone.length * 30 :30, maxHeight: '70vh',marginTop:zone?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,overflowY:'scroll'}}>
               {zone.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setSelectZone(false)
                     
                        
                       setSelectedZone({
                        "name": data.name,
                        "region": data.region,
                        "zoneId":data.zoneId})
                        
                       var woreda: Woreda = woredaDetail as Woreda;
                       woreda.zone = {
                        "name": data.name,
                        "region": data.region,
                        "zoneId":data.zoneId
                    };

                       setWoredaDetail(woreda)
                    
                      }}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
               </div>

            }

            <div onClick={()=>{ setSelectRegion(true)}} style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Region</p>
            <input onChange={(e) => {
                 var woreda: Woreda = woredaDetail as Woreda;
                    woreda.region.name = e.target.value;
                    setWoredaDetail(woreda)
                }}
                
             defaultValue={woredaDetail?.region?.name}  style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            
            
            
               
               </div>
               <button onClick={() => { updateWoreda(woredaDetail as Woreda) }} style={{width:150,height:50,background:'#ab1414',position:'absolute',right:10, bottom:0, color:'white'}}>Update </button>

           
              
                </div>

            

        );
    }
    
    export default DetailWoreda;
