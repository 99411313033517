import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Woreda } from '../../model/woreda';
import { Zone } from '../../model/zone';
import { Region } from '../../model/region';
import {MdKeyboardArrowDown} from 'react-icons/md';

function Createworeda() {
    
    const [eventDetail, setEventDetail] = useState<any[]> ([]);
    const [woredaDetail,setworedaDetail] = useState<Woreda>(new Woreda());
    const [Filters,setFilters] = useState<Boolean>(false);
    const [Regions,setRegions] = useState<Region[]>([]);
    const [Zones, setZones] = useState<Zone[]>([]);
    const [FilterList,setFilterList] = useState<string[]>(["Contains","Equals","NotEquals","Starts","Ends",]);
    const [selectedRegion,setSelectedRegion] = useState<Region>();
    const [selectedZone,setSelectedZone] = useState<Zone>();
    
    const [Filter,setFilter] = useState<Boolean>(false);
    const [FilterLists,setFilterLists] = useState<string[]>(["Contains","Equals","NotEquals","Starts","Ends",]);
    const [selectedFilters,setSelectedFilters] = useState<String>("");
    
    useEffect(()=>{
      
      axios.get(`http://localhost:4000/cms/getregion/all`)
    .then(function (response) {
   
   setRegions(response?.data?.data);


    }).catch((err) => { 

      console.log("ERRR: ", err);
    })

    axios.get(`http://localhost:4000/cms/getzone/all`)
    .then(function (response) {
   
      setZones(response?.data?.data);
   
   
       }).catch((err) => { 
   
         console.log("ERRR: ", err);
       })

    
    },[])


    const createworeda = (woredaDetal: Woreda) =>{
        axios.post(`http://localhost:4000/cms/createworeda`,{
            data: woredaDetail,
            header: {
                "content-type":"application/json"
            }
        }
         )
        .then(function (response) {
       


        })
        .catch(function (error) {
          // handle error
          console.log(error);
          return error;
    
        })
        .then(function () {
          // always executed
        
          
    
        });
    }

        return(
            

            <div style={{width:'92%',height:'90vh',position:'relative',display:'flex',flexDirection:'column',background:'white', padding:0,margin:0,marginTop:50,justifySelf:'flex-end',marginLeft:'8%'}}>
                
                <div style={{width:'98%',overflow:'scroll', backgroundColor:'lightgray',display:'flex', height:'80vh',top:13,position:'absolute', alignSelf:'center',alignContent:'center',flexDirection:'column', margin:0,padding:0}}>
                <div>
                <p style={{fontSize:'xx-large',margin:'15px'}}> Create woreda</p>
                 </div>
            <div style={{width:'96%',margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
                <p style={{fontSize:14,margin:5}}>Name</p>
            <input 
            onChange={(e) => {
                 var woreda: Woreda = woredaDetail as Woreda;
                    woreda.name = e.target.value;
                    setworedaDetail(woreda)

            }} 
            placeholder={"Woreda Name"} style={{width:'98%',marginBottom:5, borderRadius:2, fontSize:14, verticalAlign:'center',paddingLeft:3, height:30,background:'white',alignSelf:'center',border:'1px gray solid', outline:'none'}} />

            </div>

            
            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Region</p>
            <div style={{width:'98%', height:Filters? Regions.length * 30 :30,marginTop:Filter?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setFilters(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedRegion?.name == ""? "Filter": selectedRegion?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilters(true) }} style={{marginTop:7}} />
            {Filters && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:200, overflowY:'scroll',background:'white'}}> 
              {Regions.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilters(false)
                        var woreda: Woreda = woredaDetail as Woreda;
                        
                       setSelectedRegion({
                        "name": data.name,
                        "regionId": data.regionId
                       }
                     
                       )}}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            <div style={{width:'96%', paddingLeft:3, margin:5, marginBottom:1,borderRadius:3, alignSelf:'center',flexDirection:'column',background:'white',height:55,padding:10}}>
            <p style={{fontSize:14,margin:5}}>Zone</p>
            <div style={{width:'98%', height:Filter? FilterList.length * 30 :30,marginTop:Filter?'2%':0,border:'1px gray solid', backgroundColor:'#fefefe',marginLeft:10,display:'flex',overflow:'auto'}}>
            
            <p onClick={()=>{ setFilter(true) }} style={{width:'98%',fontSize:14,margin:7,marginTop:7}}> {selectedZone?.name == ""? "Filter": selectedZone?.name}</p>
            <MdKeyboardArrowDown onClick={()=>{ setFilter(true) }} style={{marginTop:7}} />
            {Filter && 
              <div style={{width:'93%',position:'absolute',zIndex:2, display:'flex',flexDirection:'column', height:200, overflowY:'scroll', background:'white'}}> 
              {Zones.map((data,index) =>{
                  return(
                    <div   onClick={()=>{ setFilter(false)
                        var woreda: Woreda = woredaDetail as Woreda;
                       
                       setSelectedZone({
                        "name": data.name,
                        "region": data.region,
                        "zoneId": data.zoneId
                       })}}  style={{background: index%2 != 0? 'gray' : 'gray',padding:2,color:'white'}}>{data.name}</div>
                  );
              })}
            </div>
            }
          
           </div>
            </div>
            
            
            
               
               </div>
               <button onClick={() => { 
                 var woreda: Woreda = woredaDetail as Woreda;
                 woreda.zone = selectedZone as Zone;
                 woreda.region = selectedRegion as Region;
                 setworedaDetail(woreda)
                 console.log(woreda);
                createworeda(woredaDetail as Woreda) }} style={{width:150,height:50,background:'#197a05',position:'absolute',right:10, bottom:0, color:'white'}}>Create </button>

           
              
                </div>

            

        );
    }
    
    export default Createworeda;
