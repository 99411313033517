import { Region } from "./region";

export  class JsiUser{
    firstName: string ;
    lastName: string;
    email: string;
    position: string;
    phoneNumber: string;

    constructor(
        firstName: string = '',
        lastName: string = '',
    email: string = '',
    position: string = '',
        phoneNumber: string = '',
    ){
            this.firstName = firstName;
            this.lastName = lastName;
            this.email = email;
            this.position = position;
            this.phoneNumber = phoneNumber;
    }
}