import React, { useEffect,useState } from 'react';
// import logo from './logo.svg';
import { FaCalendar, FaHospitalSymbol, FaTable,FaPersonBooth, FaAirFreshener , FaSearch} from 'react-icons/fa'
import {MdKeyboardArrowDown, MdTranslate} from 'react-icons/md';
import {findEventsByName} from '../api/endpoints';
import logo from '../assets/Logo.png';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';




export default function Login(){
    const navigate = useNavigate();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");


    const validate = (username: string, password: String) => {
        if(username=="admin" && password=="admin"){
            alert("logged in" );
            navigate('/');
          
        }
        else{
            alert(`incorrect Credientals `) 
        }
     
    }

return(
<div style={{width:'100%',height:'100%',background:'rgb(167 169 179)',flexDirection:'row', padding:'25vh', justifyContent:'center'}}>

<div style={{width:350,height:350,position:'relative',left:'27%',  boxShadow:'0px 0px 4px 4px gray',background:'white',alignSelf:'center',display:'flex',flexDirection:'column', justifyContent:'center'}}>
        <img src={logo} width={70} height={55} style={{width:60,height:50,position:'relative',borderRadius:'50%',margin:5,alignSelf:'center'}} />
  
    <input placeholder='User Name' onChange={(e) => {
                 var username: string;
                    username = e.target.value;
                    setUsername(username)

            }} style={{  border:'1px black solid', borderTop:'none', borderRight:'none', borderLeft:'none', width:'90%',outline:"none",height:30, padding:5,margin:10}} />
    <input type={"password"} placeholder='Password' onChange={(e) => {
                 var password: string;
                    password = e.target.value;
                    setPassword(password)

            }}style={{  border:'1px black solid', borderTop:'none', borderRight:'none', borderLeft:'none', width:'90%',outline:"none",height:30, padding:5,margin:10}} />

        <button onClick={()=>{validate(username, password)}} style={{width:'90%',height:50,alignSelf:'center',position:'relative',marginTop:30,background:'#ab1414',color:'white',fontWeight:'bold',borderWidth:0,borderRadius:5}}> Login</button> 
</div>

</div>
    );


}